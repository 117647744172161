import React from "react";

/*** COMPONENTS ***/
import Main from "../../components/main";
import SEO from "../../components/seo";
import "../../styles/project.css";
import chatbotHeader from "../../images/chatbot.png";

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes";

function openGithubChatbot() {
  window.open(
    "https://github.com/johannagranstroem/TNM108-Machine-Learning-for-Social-Media",
    "_blank"
  );
}

const chatbotPage = () => (
  <Main>
    <SEO title="Chatbot" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-Chatbot" src={chatbotHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">CHATBOT</p>
          <p class="project-header-text-grey">MACHINE LEARNING</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Language</p>
        <p class="project-bulletpoint-text"> &#9642; Python</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2018-11-17</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          Together with Ronja Faltin, I developed a&nbsp;chatbot&nbsp;by using
          the methods
          <b> Natural Language Processing</b> and{" "}
          <b>Natural Language Toolkit</b>. We created a clean dataset for our
          bot to get its data from.
          <br />
          <br />
          The dataset contained sentences for the bot to use as replies
          depending on the input from the user. <b>Cosine Similarity</b> was
          used to compare the similarity between the asked question to a
          question in our dataset. By using Natural Language Processing the
          computer could understand, analyze, and manipulate the language in the
          dataset. We used Natural Language Toolkit for text processing such as
          lemmatization, stemming, and tagging. Lemmatization and stemming are
          Text Normalization’s techniques used to prepare text and words for
          further operations.
          <br />
          <br />
          You can checkout the project on{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubChatbot()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
);

export default chatbotPage;
